<template>
    <div class="main-container" v-show="!isLoading">
        <!-- LEFT MENU -->
        <LeftMenu
            :entityParent="entityParent"
            :entityType="entityType"
            :activeTab="activeTab"
            :resId="idParent"
            :name="name"
            :displayed="displayed"
            :adress="adress"
            :comment="comment"
            :id="id"
            :idParent="idParent"
            @updateActiveTab="udpdateActiveTab"
            @editAction="editAction"
        ></LeftMenu>

        <div class="content-panel">
            <v3-warning
                v-if="activeTab === 'authorizations' || activeTab === 'codes'"
            />
            <!-- DETAILS -->
            <Home
                v-show="activeTab === 'details'"
                :activeTab="activeTab"
                :path="path"
                :displayMode="displayMode"
                :accesses="accesses"
                :entityParent="entity"
                :idParent="id"
                :countAccess="countAccess"
                :stairwells="stairwells"
                @showMore="showMore"
                @createAction="createAction"
            />

            <!-- AUTHORIZATIONS -->
            <div
                id="v1-auths-bloc"
                class="tab-panel"
                v-show="activeTab === 'authorizations'"
            >
                <loader class="v1-tab-loader"></loader>
            </div>

            <!-- CODES -->
            <div class="tab-panel" v-if="activeTab === 'codes'">
                <CodeByAccessView
                    :residenceId="path.residence.id"
                    residencePart="Building"
                />
            </div>

            <!-- CENTRAL UNITS -->
            <div class="tab-panel" v-show="activeTab === 'central-units'">
                <central-units-list
                    :idResidence="idParent"
                    :activeTab="activeTab"
                >
                </central-units-list>
            </div>

            <!-- SUBACCOUNTS -->
            <div class="tab-panel" v-show="activeTab === 'subaccounts'">
                <subaccounts-list
                    :subaccounts="subaccounts"
                    :agences="agences"
                ></subaccounts-list>
            </div>
        </div>
    </div>
    <div v-show="isLoading" class="panels-loading">
        <Loader />
    </div>
</template>

<script>
import { getStairwellSummaryById } from "@/services/intratone/stairwell.js"
import { entityType } from "@/enums"
import { mapState } from "vuex"
import { getAccess } from "@/services/intratone/access"
import { getResidenceSubaccountsById } from "@/services/intratone/residence.js"

import Loader from "@/components/basic/Loader.vue"
import LeftMenu from "./LeftMenu.vue"
import httpIntratone from "@/services/httpIntratone.js"
import v1mixin from "@/mixins/v1.js"
import Home from "./Home.vue"
import centralUnitsList from "@/components/entities/CentralUnitsList.vue"
import SubaccountsList from "@/components/entities/SubaccountsList.vue"
import CodeByAccessView from "../codeByAccess/CodeByAccessView.vue"
import V3Warning from "@/components/V3Warning.vue"

export default {
    name: "BuildingDetails",
    components: {
        LeftMenu,
        Home,
        centralUnitsList,
        SubaccountsList,
        Loader,
        CodeByAccessView,
        V3Warning,
    },
    mixins: [v1mixin],
    data() {
        return {
            isLoading: true,
            // Parent type and data
            entityParent: null,
            idParent: null,
            nameParent: null,
            // Entity path
            path: null,
            id: null,
            name: null,
            comment: null,
            adress: null,
            type: null,
            manage: null,
            displayed: false,
            // Accesses
            accesses: [],
            // Stairwells
            stairwells: [],
            // Active tab
            activeTab: "details",
            // Show all accesses
            showAllAccesses: false,
            codesDisplayed: false,
            accessPage: 1,
            accessTotalPage: 1,
            countAccess: 0,
            subaccounts: [],
            agences: [],
            //entity Object
            entity: entityType.BUILDING,
            entityType,
        }
    },
    computed: {
        allStairwells() {
            return this.buildings.concat(this.stairwells)
        },

        ...mapState({
            displayMode: (state) => state.account.displayMode,
        }),
        tabParameter() {
            return this.$route.params.tab !== ""
                ? this.$route.params.tab
                : "details"
        },
    },
    watch: {
        tabParameter() {
            if (this.$route.name === "Building") {
                this.udpdateActiveTab(this.tabParameter)
            }
        },
    },
    methods: {
        showMore() {
            getAccess({
                idcag: this.id,
                for: "linked",
                limit: this.countAccess,
                stat: "cles",
            }).then((response) => {
                response.data.list.forEach((newAccess) => {
                    if (
                        this.accesses.find(
                            (exist) => exist.id === newAccess.id
                        ) === undefined
                    ) {
                        this.accesses.push(newAccess)
                    }
                })
                this.accessPage = parseInt(response.data._page)
                this.accessTotalPage = parseInt(response.data._pages)
            })
        },
        createAction() {
            this.openV1Modal("data/batiment_add", "Act_Zone")
        },
        editAction(value) {
            if (value === 1) {
                this.goToV1View(
                    "data/batiment/edit=1&type=BATIMENT&id=" +
                        this.path.batiment.id
                )
            } else {
                this.goToV1View(
                    "data/residence/load/edit=1&id=" + this.path.residence.id
                )
            }
        },
        async load() {
            this.activeTab = "details"
            this.isLoading = true
            this.idParent = null
            this.nameParent = null
            this.id = null
            this.name = null
            this.comment = null
            this.adress = null
            this.type = null
            this.accesses = []
            this.stairwells = []
            this.path = null

            await this.loadBuilding()
            if (this.tabParameter === "" || this.tabParameter === undefined) {
                this.udpdateActiveTab("details")
            } else {
                this.udpdateActiveTab(this.tabParameter)
            }
        },
        async loadBuilding() {
            try {
                // V1 site request in order to set v1 site session with building
                // Getting building
                let buildingResponse = await getStairwellSummaryById(
                    this.$route.params.id
                )
                // Stairwells
                if (buildingResponse.data.cages)
                    this.stairwells = buildingResponse.data.cages.list
                else this.$router.push({ path: "/error/404" })

                if (buildingResponse === null) {
                    throw new Error()
                }
                if (buildingResponse.state === "error") {
                    if (buildingResponse.code === "API_RESTRICTED") {
                        this.$router.push({
                            path: "/error/401",
                        })
                    } else {
                        this.$router.push({
                            path: "/error/404",
                        })
                    }
                    return
                }

                // Building data
                this.id = buildingResponse.data.cage.id
                this.name = buildingResponse.data.cage.nom
                this.comment = buildingResponse.data.cage.comments
                this.adress = buildingResponse.data.cage.adr
                this.path = buildingResponse.data.path
                this.idParent = this.path.residence.id
                this.nameParent = this.path.residence.name
                this.entityParent = entityType.RESIDENCE

                // Accesses
                // if(buildingResponse.data.acces) {
                this.accesses = buildingResponse.data.acces.list.splice(0, 8)
                this.countAccess = buildingResponse.data.acces._count
                // }
                this.displayed = buildingResponse.data.cage.cage_linked
                    ? true
                    : false
                // Stop loading
                this.isLoading = false
                const dataFilters = {
                    building_nom: buildingResponse.data.cage.nom,
                    building_cnil: buildingResponse.data.cage.cnil,
                    building_path: buildingResponse.data.path,
                }
                let v1Call = await httpIntratone.post(
                    "data/batiment/init.php",
                    `id=${this.$route.params.id}&type=BATIMENT&SID=${
                        this.$store.getters["auth/getIntratoneDev2SID"]
                    }&buildingDetails=${JSON.stringify(dataFilters)}`
                )
            } catch (error) {
                console.log(error)
            }
        },

        udpdateActiveTab(tab) {
            this.activeTab = tab
            if (this.activeTab === "authorizations") {
                this.openV1Modal(
                    "data/autorisation/autos/hide_bar=1&res=" + this.idParent,
                    "v1-auths-bloc"
                )
            } else if (this.activeTab === "codes") {
                this.codesDisplayed = true
            } else if (this.activeTab === "subaccounts") {
                this.loadSubaccounts()
            }
        },

        async loadSubaccounts() {
            try {
                let subaccountsResponse = await getResidenceSubaccountsById(
                    this.idParent
                )
                if (subaccountsResponse === null) {
                    throw new Error()
                }
                this.subaccounts = subaccountsResponse.data.subaccounts
                this.agences = subaccountsResponse.data.agences
            } catch (error) {
                console.log(error)
            }
        },
    },
    created() {
        this.load()
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";
@import "@/assets/scss/variables/fontSize.scss";

#v1-auths-bloc :deep() {
    @import "./../../assets/scss/v1-scss/style.scss";
    @import "./../../assets/scss/v1-scss/datepicker.scss";
    @import "./../../assets/scss/v1-scss/cle.scss";
    @import "./../../assets/scss/v1-scss/bootstrap/css/bootstrap.scss";
    @import "./../../assets/scss/v1-scss/bootstrap/css/bootstrap.min.scss";
    text-align: center;
}

@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}

@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}

.tab-panel {
    height: 100%;
}

.main-container {
    display: flex;
    width: 100%;
    height: 100%;
}
</style>
